body {
  overflow-x: hidden;
  height: 100%;
}

.bodyContainer {
  margin-bottom: 100px;
}

.name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: -20px;
}

.svgAnimation {
  width: 100px;
  height: 120px;
  display: flex;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2);
  margin-right: -10px;
  margin-left: -7px;
}

.item {
  width: 56%;
  overflow: visible;
  stroke: #000000;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.typewriter {
  font-size: 30px;
}

.cursor {
  font-size: 30px;
  animation-name: blink;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  opacity: 1;
}

@keyframes blink {
  from { opacity: 1; }
  to { opacity: 0; }
}

.profilePicture {
  border-radius: 50%;
  width: 180px;
}

.bodyTitle {
  font-family: 'Raleway';
}

.bodyText {
  text-align: left;
}

.footer {
  align-items: center;
  flex-direction: column;
  height: 100px;
  margin-top: auto;
}